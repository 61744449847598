// src/components/DPlayerComponent.tsx

import React, { useEffect, useRef } from 'react';
import DPlayer from 'dplayer';
import Hls from 'hls.js';

interface DPlayerComponentProps {
    videoUrl: string;
    thumbnailUrl?: string;
}

const DPlayerComponent: React.FC<DPlayerComponentProps> = ({ videoUrl, thumbnailUrl }) => {
    const dPlayerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (dPlayerRef.current) {
            const dp = new DPlayer({
                container: dPlayerRef.current,
                screenshot: true,
                video: {
                    url: videoUrl,
                    pic: thumbnailUrl,
                    type: 'customHls',
                    customType: {
                        customHls: function (video: HTMLVideoElement) {
                            if (Hls.isSupported()) {
                                const hls = new Hls();
                                hls.loadSource(videoUrl);
                                hls.attachMedia(video);
                            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                                video.src = videoUrl;
                            }
                        },
                    },
                },
            });

            return () => {
                dp.destroy();
            };
        }
    }, [videoUrl, thumbnailUrl]);

    return <div ref={dPlayerRef} />;
};

export default DPlayerComponent;