const Header: React.FC = () => {
    return (
        <header className="mt-0 absolute z-[1999] w-[100%] py-4" role="banner">

            <div className="max-w-5xl mx-auto">
                <div className="flex flex-row">
                    <div className="site-logo">
                        <a href="index.html">
                            <img src="images/logo.png" alt="Logo" />
                        </a>
                    </div>
                    <div className="ml-auto">
                        <nav className="" role="navigation">
                            <ul className="flex flex-row-reverst">
                                <li className="w-20 active">
                                    <a href="index.html" className="text-white text-xs uppercase font-normal inline-block tracking-widest">Home</a></li>
                                <li className="w-20 ">
                                    <a href="matches.html" className="text-white text-xs uppercase font-normal inline-block tracking-widest">Matches</a></li>
                                <li className="w-20 ">
                                    <a href="players.html" className="text-white text-xs uppercase font-normal inline-block tracking-widest">Players</a></li>
                                <li className="w-20 ">
                                    <a href="blog.html" className="text-white text-xs uppercase font-normal inline-block tracking-widest">Blog</a></li>
                                <li className="w-20 ">
                                    <a href="contact.html" className="text-white text-xs uppercase font-normal inline-block tracking-widest">Contact</a></li>
                            </ul>
                        </nav>
                        {/* 
          <a href="#" className="d-inline-block d-lg-none site-menu-toggle js-menu-toggle text-black float-right text-white"><span
            className="icon-menu h3 text-white"></span></a> */}
                    </div>
                </div>
            </div>

        </header>)
}
export default Header